import React, { useState, useEffect, useLayoutEffect } from "react"
import { StaticImage } from "gatsby-plugin-image"
import { range } from "lodash"
import { useCookies } from "react-cookie"
import { isSafari, isIOS } from "react-device-detect"

const AgeVerification = () => {
  const [cookies, setCookie, removeCookie] = useCookies(["ageVerify"])
  const [date, setDate] = useState({
    year: 1980,
    month: new Date().getMonth(),
    day: new Date().getDate(),
  })

  const onChange = (event, type) => {
    setDate({
      ...date,
      [type]: event.target.value,
    })
  }

  const onReject = event => {
    event.preventDefault()
    alert("抱歉，您的年齡和地區不允許您在此時登入")
  }

  const onClick = event => {
    event.preventDefault()
    setCookie("ageVerify", true, { maxAge: 3600 })
  }

  useLayoutEffect(() => {
    if (isSafari || isIOS) {
      document.getElementById("year")?.classList?.add("indent-select")
      document.getElementById("month")?.classList?.add("indent-select-large")
      document.getElementById("day")?.classList?.add("indent-select-large")
    }
  }, [isSafari, isIOS])

  const verified =
    !!cookies.ageVerify ||
    cookies.ageVerify === "true" ||
    cookies.ageVerify === true

  if (verified) return null

  return (
    <div
      className={`w-screen h-screen ${
        verified ? "hidden" : "flex"
      } px-6 flex-col bg-white justify-center items-center absolute inset-0`}
    >
      <div className="w-full md:max-w-lg flex flex-col justify-center items-center">
        <StaticImage
          src="../../images/Desktop/05.LOGO_Gold@2x.png"
          width={106}
          placeholder="blurred"
          alt="gold-logo"
        />
        <p className="font-normal text-base mt-5 mb-8">請輸入你的出生年月日</p>
        <div className="grid gap-3 grid-cols-3 w-full">
          <select
            className="rounded text-center px-1 py-2 bg-white border border-grey"
            name="subject"
            id="year"
            value={date.year}
            onChange={e => onChange(e, "year")}
          >
            {range(1900, new Date().getFullYear()).map(year => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
          <select
            className="rounded text-center px-1 py-2 bg-white border border-grey"
            name="subject"
            value={date.month}
            id="month"
            onChange={e => onChange(e, "month")}
          >
            {range(1, 12).map(month => (
              <option key={month} value={month}>
                {String(month).padStart(2, "0")}
              </option>
            ))}
          </select>
          <select
            className="rounded text-center px-1 py-2 bg-white border border-grey"
            name="subject"
            value={date.day}
            id="day"
            onChange={e => onChange(e, "day")}
          >
            {range(1, 31).map(date => (
              <option key={date} value={date}>
                {String(date).padStart(2, "0")}
              </option>
            ))}
          </select>
        </div>
        <div className="divider border-grey w-full mt-5 mb-8">或</div>
        <div className="flex items-stretch justify-evenly mb-8 w-full">
          <button
            className="rounded bg-secondary py-2 w-40 text-white"
            onClick={onReject}
          >
            未滿十八
          </button>
          <button
            className="rounded bg-gold py-2 w-40 text-white"
            onClick={onClick}
          >
            我已滿十八
          </button>
        </div>
        <p className="text-base">我已達所在地法定飲酒年齡</p>
      </div>
    </div>
  )
}
export default AgeVerification
