import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Language from "./Language"
import { Link } from "gatsby"

const Header = () => {
  return (
    <header className="sticky top-0 w-full flex justify-between items-center bg-white z-10">
      <div className="flex justify-between items-center w-full px-4 md:px-24">
        <Link to="/">
          <StaticImage
            src="../images/logo.png"
            alt="logo"
            width={155}
            height={17.34}
            placeholder="none"
          />
        </Link>
        <div className="flex items-center">
          <div className="hidden md:flex md:pr-20">
            <Language lang="zhCN" />
            <span className="px-3">-</span>
            <Language lang="zhTW" />
          </div>
          <label htmlFor="my-drawer" className="drawer-button cursor-pointer">
            <StaticImage
              src="../images/icons/menu.png"
              alt="menu"
              width={60}
              placeholder="none"
            />
          </label>
        </div>
      </div>
    </header>
  )
}

export default Header
