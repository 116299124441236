import i18n from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import { initReactI18next } from "react-i18next"
import zhCN from "./locales/zh-cn.json"
import zhTW from "./locales/zh-tw.json"

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      zhCN: {
        translation: zhCN,
      },
      zhTW: {
        translation: zhTW,
      },
    },
    fallbackLng: "zhTW",
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export default i18n
