import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Language from "../Language"
import { useTranslation } from "react-i18next"

const MobileFooter = () => {
  const { t } = useTranslation()
  return (
    <div
      className="px-5 py-7 flex md:hidden flex-col justify-center items-center w-full pb-20"
      style={{
        background: "linear-gradient(0deg, #424242, #424242), #282627",
      }}
    >
      <div className="flex justify-evenly items-center text-gold mb-12">
        <Language lang="zhCN" /> - <Language lang="zhTW" />
      </div>
      <div
        className="bg-gold"
        style={{
          height: 1,
          width: 40,
          transform: "rotate(-75deg)",
          transformOrigin: "center",
        }}
      />
      <h3
        className="text-gold text-sm mb-6 mt-12"
        style={{ letterSpacing: "0.6em" }}
      >
        FOLLOW US
      </h3>
      <div className="flex justify-evenly items-center gap-8 mb-12">
        {/* <a href="https://www.facebook.com/Duke-Blandon-110873124762688">
          <StaticImage
            src="../../images/icons/00.icon_facebook@2x.png"
            width={36}
            alt="facebook-icon"
            placeholder="blurred"
          />
        </a>
        <a href="https://www.instagram.com/dukeblandon/">
          <StaticImage
            src="../../images/icons/00.icon_instagram@2x.png"
            width={36}
            alt="ig-icon"
            placeholder="blurred"
          />
        </a> */}
        {/* <a>
          <StaticImage
            src="../../images/icons/00.icon_PG_talk@2x.png"
            width={36}
            alt="pg-talk-icon"
            placeholder="blurred"
          />
        </a>
        <a>
          <StaticImage
            src="../../images/icons/00.icon_LINE@2x.png"
            width={36}
            alt="line-icon"
            placeholder="blurred"
          />
        </a> */}
      </div>
      <div
        className="bg-gold"
        style={{
          height: 1,
          width: 40,
          transform: "rotate(-75deg)",
          transformOrigin: "center",
        }}
      />
      <div className="mt-12" style={{ width: 310 }}>
        <p className="text-white text-xs font-normal tracking-loose leading-loose">
          {t("footer.phone")}：<a href="tel:02-26532126">02-26532126</a>
        </p>
        <p className="text-white text-xs font-normal tracking-loose leading-loose">
          Email：
          <a href="mailto:info@dukeblandon.com">info@dukeblandon.com</a>
        </p>
        <p className="text-white text-xs font-normal tracking-loose leading-loose">
          {t("footer.address1")}
        </p>
        <p className="text-white text-xs font-normal tracking-loose leading-loose">
          {t("footer.address2")}
        </p>
      </div>
    </div>
  )
}

export default MobileFooter
