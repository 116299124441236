import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { useTranslation } from "react-i18next"

const DesktopFooter = () => {
  const { t } = useTranslation()

  return (
    <div
      className="hidden justify-between items-container w-full px-28 py-12 md:flex"
      style={{
        background: "linear-gradient(0deg, #424242, #424242), #282627",
      }}
    >
      <div
        className="font-extralight text-sm text-white tracking-loose"
        style={{ maxWidth: 360 }}
      >
        <p className="text-sm font-extralight mb-1">
          {t("footer.phone")}：02-26532126
        </p>
        <p className="text-sm font-extralight mb-1">
          <a href="mailto:info@dukeblandon.com">Email：info@dukeblandon.com</a>
        </p>
        <p className="text-sm font-extralight mb-1">{t("footer.address1")}</p>
        <p className="text-sm font-extralight mb-1">{t("footer.address2")}</p>
      </div>
      <div>
        <p
          className="text-gold font-extralight mb-5 text-center"
          style={{ letterSpacing: "0.6em" }}
        >
          FOLLOW US
        </p>
        <div className="flex justify-evenly items-center gap-8">
          {/* <a href="https://www.facebook.com/Duke-Blandon-110873124762688">
            <StaticImage
              src="../../images/icons/00.icon_facebook@2x.png"
              width={36}
              alt="facebook-icon"
              placeholder="blurred"
            />
          </a>
          <a href="https://www.instagram.com/dukeblandon/">
            <StaticImage
              src="../../images/icons/00.icon_instagram@2x.png"
              width={36}
              alt="ig-icon"
              placeholder="blurred"
            />
          </a> */}
          {/* <a>
            <StaticImage
              src="../../images/icons/00.icon_PG_talk@2x.png"
              width={36}
              alt="pg-talk-icon"
              placeholder="blurred"
            />
          </a>
          <a>
            <StaticImage
              src="../../images/icons/00.icon_LINE@2x.png"
              width={36}
              alt="line-icon"
              placeholder="blurred"
            />
          </a> */}
        </div>
      </div>
    </div>
  )
}

export default DesktopFooter
