/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { I18nextProvider, useTranslation } from "react-i18next"
import i18n from "../data/i18n"
import Header from "./header"
import { StaticImage } from "gatsby-plugin-image"
import AgeVerification from "./AgeVerification"
import DesktopFooter from "./DesktopFooter"
import MobileFooter from "./MobileFooter"
import { CookiesProvider } from "react-cookie"
import Sidebar from "./Sidebar"
import { useCookies } from "react-cookie"

const AgeVerificationContainer = () => {
  const [cookies] = useCookies(["ageVerify"])

  if (cookies.ageVerify === "true" || cookies.ageVerify) {
    return <></>
  } else {
    return <AgeVerification />
  }
}

const Layout = ({ children }) => {
  const { t } = useTranslation()
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <I18nextProvider i18n={i18n}>
      <CookiesProvider>
        <div className="drawer drawer-end container mx-auto min-h-screen">
          <input id="my-drawer" type="checkbox" className="drawer-toggle" />
          <div className="drawer-content flex flex-col">
            <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
            <div className="flex-grow flex flex-col">
              <main className="bg-white flex-grow">{children}</main>
              <DesktopFooter />
              <MobileFooter />
              <footer
                className="sticky bottom-0 w-full flex items-center py-4 text-white text-sm justify-center md:text-3xl tracking-wide md:tracking-widest"
                style={{
                  background:
                    "linear-gradient(0deg, #424242, #424242), #282627",
                }}
              >
                {t("footer.dontDrunk")}
                <StaticImage
                  src="../images/icons/00.icon_ban.png"
                  width={40}
                  className="mx-4"
                  placeholder="blurred"
                  alt=""
                />
                {t("footer.dontDrink")}
              </footer>
            </div>
          </div>
          <Sidebar />
          <AgeVerificationContainer />
        </div>
      </CookiesProvider>
    </I18nextProvider>
  )
}

export default Layout
