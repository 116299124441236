import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"

const Language = ({ lang }) => {
  const { i18n } = useTranslation()

  const changeLanguage = lng => {
    console.log(i18n.language)
    console.log(lng)
    i18n.changeLanguage(lng)
  }

  const currentLang = useMemo(() => {
    return i18n.language
  }, [i18n.language])

  return (
    <a
      className={`${
        currentLang === lang ? "text-gold" : "text-grey"
      } text-sm md:text-base cursor-pointer mx-2`}
      onClick={() => changeLanguage(lang)}
    >
      {lang === "zhCN" ? "简中" : "繁中"}
    </a>
  )
}

export default React.memo(Language)
