import React from "react"
import { useTranslation } from "react-i18next"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const Sidebar = () => {
  const { t } = useTranslation()
  const onClick = () => {
    const el = document.getElementById("close") as HTMLElement
    el?.click()
  }

  return (
    <div className="drawer-side">
      <label htmlFor="my-drawer" className="drawer-overlay"></label>
      <div className="bg-white w-full md:w-80 flex flex-col pt-16 md:pt-36 items-center relative">
        <label
          htmlFor="my-drawer"
          id="close"
          className="draw-button absolute top-2 right-2 md:top-1.5 md:right-1.5 cursor-pointer"
        >
          <StaticImage
            src="../../images/icons/03.icon_close@2x.png"
            width={60}
            placeholder="none"
            alt="close"
          />
        </label>
        <StaticImage
          src="../../images/Desktop/03.LOGO_BLK@2x.png"
          width={82}
          placeholder="blurred"
          alt="close"
        />
        <div
          className="mt-5 mb-10 bg-gold"
          style={{ height: 1, width: 130 }}
        ></div>
        <ul className="flex flex-col justify-center items-center">
          <li className="mb-10">
            <Link
              className="font-light text-lg tracking-large"
              to="/about"
              onClick={onClick}
            >
              {t("sidebar.about")}
            </Link>
          </li>
          <li className="mb-10">
            <Link
              className="font-light text-lg tracking-large"
              to="/sorghum"
              onClick={onClick}
            >
              {t("sidebar.sorghum")}
            </Link>
          </li>
          <li className="mb-10">
            <Link
              className="font-light text-lg tracking-large"
              to="/location"
              onClick={onClick}
            >
              {t("sidebar.location")}
            </Link>
          </li>
          <li className="mb-10">
            <a
              className="font-light text-lg tracking-large"
              href="https://www.instagram.com/DUKE.BLANDON/?UTM_MEDIUM=COPY_LINK"
            >
              Instagram
            </a>
          </li>
          <li className="mb-10">
            <a
              className="font-light text-lg tracking-large"
              href="https://www.facebook.com/PROFILE.PHP?ID=100074194786082"
            >
              Facebook
            </a>
          </li>
          <li className="mb-10">
            <Link
              className="font-light text-lg tracking-large"
              to="/contact"
              onClick={onClick}
            >
              {t("sidebar.contact")}
            </Link>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Sidebar
